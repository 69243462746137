var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"编辑","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"运单号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'tracking_number',
            {
              initialValue: _vm.item.tracking_number,
              rules: [
                { required: true, message: '请输入运单号' },
                { max: 128, message: '超出最大长度(128)' },
              ],
            },
          ]),expression:"[\n            'tracking_number',\n            {\n              initialValue: item.tracking_number,\n              rules: [\n                { required: true, message: '请输入运单号' },\n                { max: 128, message: '超出最大长度(128)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"分拣道口 A"}},[_c('CrossingSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'target_crossing_a',
            { initialValue: _vm.item.target_crossing_a, rules: [{ required: true, message: '请选择分拣道口 A' }] },
          ]),expression:"[\n            'target_crossing_a',\n            { initialValue: item.target_crossing_a, rules: [{ required: true, message: '请选择分拣道口 A' }] },\n          ]"}],attrs:{"productionLine":"a"}})],1),_c('a-form-item',{attrs:{"label":"分拣道口 B"}},[_c('CrossingSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'target_crossing_b',
            { initialValue: _vm.item.target_crossing_b, rules: [{ required: true, message: '请选择分拣道口 B' }] },
          ]),expression:"[\n            'target_crossing_b',\n            { initialValue: item.target_crossing_b, rules: [{ required: true, message: '请选择分拣道口 B' }] },\n          ]"}],attrs:{"productionLine":"b"}})],1),_c('a-form-item',{attrs:{"label":"拦截原因"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['reason', { initialValue: _vm.item.reason, rules: [{ max: 256, message: '超出最大长度(256)' }] }]),expression:"['reason', { initialValue: item.reason, rules: [{ max: 256, message: '超出最大长度(256)' }] }]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"启用状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_enable', { initialValue: _vm.item.is_enable, valuePropName: 'checked' }]),expression:"['is_enable', { initialValue: item.is_enable, valuePropName: 'checked' }]"}]})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }